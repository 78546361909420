import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Stack, Zoom } from '@mui/material'
import Fab from '@mui/material/Fab'
import { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'

interface Props {
  children: any
}

export default function Layout({ children }: Props) {
  const [trigger, setTrigger] = useState(false)
  useEffect(() => {
    const controlTrigger = () => {
      const h = window.scrollY || document.documentElement.scrollTop
      if (h > 100) {
        setTrigger(true)
      } else {
        setTrigger(false)
      }
    }
    controlTrigger()
    window.addEventListener('scroll', controlTrigger)
    return () => {
      window.removeEventListener('scroll', controlTrigger)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <Stack sx={{ height: (theme) => theme.mixins.toolbar.height }}>
        <Header />
      </Stack>
      <Box component="main">{children}</Box>
      <Footer />
      <Zoom in={trigger}>
        <Box
          onClick={() => {
            window.scroll({
              behavior: 'smooth',
              top: 0,
            })
          }}
          role="presentation"
          sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 99999 }}
        >
          <Fab color="secondary" size="small">
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Zoom>
    </Box>
  )
}
